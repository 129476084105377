<template>
    <div class="app-container">
        <el-card class="box-card">
            <div slot="header" class="clearfix">
                <span>{{ $t('sells.orders.header-1') }}</span>
                <span style="float: right">
                    <el-button type="primary" size="mini" icon="el-icon-check" plain @click.native="applyFilters">{{
                        $t('general.apply')
                    }}</el-button>
                    <el-button type="warning" size="mini" icon="el-icon-refresh" plain @click.native="resetFilters">
                        {{ $t('general.reset') }}</el-button
                    >
                </span>
            </div>
            <el-row :gutter="12">
                <el-col :xs="24" :sm="12" :md="12" :lg="6">
                    <div class="sub-title">{{ $t('sells.orders.title-1') }}</div>
                    <el-select
                        class="fullWidth"
                        v-model="filters.order_status.value"
                        filterable
                        :placeholder="$t('sells.orders.ph-1')"
                    >
                        <el-option :label="$t('sells.orders.all')" value="0"> </el-option>
                        <el-option
                            v-for="item in statusValues"
                            :key="item.value"
                            :label="item.text"
                            :value="item.value"
                        >
                        </el-option>
                    </el-select>
                </el-col>
                <el-col :xs="24" :sm="12" :md="12" :lg="6">
                    <div class="sub-title">{{ $t('sells.orders.title-2') }}</div>
                    <el-input :placeholder="$t('sells.orders.ph-2')" v-model="filters.order.value"></el-input>
                </el-col>
                <el-col :xs="24" :sm="12" :md="12" :lg="6">
                    <div class="sub-title">{{ $t('sells.orders.title-3') }}</div>
                    <el-input :placeholder="$t('sells.orders.ph-3')" v-model="filters.invoice.value"></el-input>
                </el-col>
                <el-col :xs="24" :sm="12" :md="12" :lg="6">
                    <div class="sub-title">{{ $t('general.client') }}</div>
                    <el-input
                        :placeholder="$t('sells.orders.ph-4')"
                        v-model="filters.customer.value"
                        class="input-with-select"
                    >
                        <el-select v-model="filters.customerSign.value" slot="prepend" placeholder="Select">
                            <el-option
                                v-for="item in otherSignsValues"
                                :label="item.text"
                                :value="item.value"
                                :key="item.value"
                            ></el-option>
                        </el-select>
                    </el-input>
                </el-col>
            </el-row>
            <div class="separator"></div>
            <el-row :gutter="12">
                <el-col :xs="24" :sm="12" :md="12" :lg="6">
                    <div class="sub-title">{{ $t('general.product') }}</div>
                    <el-input
                        :placeholder="$t('sells.orders.ph-5')"
                        v-model="filters.product.value"
                        class="input-with-select"
                    >
                        <el-select v-model="filters.productSign.value" slot="prepend">
                            <el-option
                                v-for="item in otherSignsValues"
                                :label="item.text"
                                :value="item.value"
                                :key="item.value"
                            ></el-option>
                        </el-select>
                    </el-input>
                </el-col>
                <el-col :xs="24" :sm="12" :md="12" :lg="6">
                    <div class="sub-title">{{ $t('sells.orders.title-4') }}</div>
                    <el-date-picker
                        class="fullWidth"
                        v-model="filters.minDate.value"
                        value-format="yyyy-MM-dd"
                        type="date"
                        :placeholder="$t('sells.orders.ph-6')"
                    >
                    </el-date-picker>
                </el-col>
                <el-col :xs="24" :sm="12" :md="12" :lg="6">
                    <div class="sub-title">{{ $t('sells.orders.title-5') }}</div>
                    <el-date-picker
                        class="fullWidth"
                        v-model="filters.maxDate.value"
                        value-format="yyyy-MM-dd"
                        type="date"
                        :placeholder="$t('sells.orders.ph-7')"
                    >
                    </el-date-picker>
                </el-col>
            </el-row>
            <div class="separator"></div>
            <el-row :gutter="12">
                <el-col :xs="24" :sm="12" :md="12" :lg="6">
                    <div class="sub-title">{{ $t('sells.orders.title-6') }}</div>
                    <el-select
                        v-model="filters.payment_method.value"
                        :placeholder="$t('sells.orders.title-6')"
                        filterable
                    >
                        <el-option v-for="item in paymentMethods" :label="item" :value="item" :key="item"></el-option>
                    </el-select>
                </el-col>
                <el-col :xs="24" :sm="12" :md="12" :lg="6">
                    <div class="sub-title">{{ $t('sells.orders.phone-filter') }}</div>
                    <el-input :placeholder="$t('sells.orders.phone-filter')" v-model="filters.phoneNumber.value">
                    </el-input>
                </el-col>
                <el-col :xs="24" :sm="12" :md="12" :lg="6">
                    <div class="sub-title">{{ $t('sells.orders.proforma-filter') }}</div>
                    <el-input :placeholder="$t('sells.orders.proforma-filter')" v-model="filters.proformaNumber.value">
                    </el-input>
                </el-col>
            </el-row>
            <el-row :gutter="12" class="mt-1">
                <el-col :xs="24" :sm="12" :md="12" :lg="6">
                    <el-checkbox :true-label="1" :false-label="0" v-model="filters.onlyPickupLocker.value">{{
                        $t('sells.orders.title-7')
                    }}</el-checkbox>
                </el-col>
            </el-row>
        </el-card>
        <div class="separator"></div>
        <el-card class="box-card">
            <div slot="header" class="clearfix">
                <span>{{ $t('general.orders') }}</span>
                <span v-if="isDemo" style="float: right">
                    <el-button @click="showGenerateModal = true" type="primary" size="mini" icon="el-icon-plus" plain
                        >Genereaza comanda</el-button
                    >
                </span>
            </div>
            <div v-if="checkIfOrders">
                <el-tabs type="border-card" v-model="selectedStatus" @tab-click="handleTabChange">
                    <el-tab-pane v-for="status in statusValues" :key="status.value">
                        <div slot="label">
                            <svg-icon :class="status.label" icon-class="primitive-dot" />
                            <span class="labelText">{{ formatLabelText(status) }}</span>
                        </div>
                        <div v-if="ordersWithStatus(status.value)">
                            <el-table
                                :data="formatedOrders(ordersWithStatus(status.value).list)"
                                border
                                style="width: 100%"
                                :size="size"
                            >
                                <el-table-column label="ID" width="180">
                                    <template slot-scope="scope">
                                        <div class="cell-container">
                                            <div>{{ scope.row.orders_id }}</div>
                                            <div>
                                                <el-tooltip
                                                    v-if="parseInt(status.value) !== 1"
                                                    class="item"
                                                    effect="dark"
                                                    :content="invoiceSeriesGenerator(scope.row)"
                                                    placement="right"
                                                >
                                                    <b class="float-right"
                                                        ><el-button
                                                            size="mini"
                                                            :disabled="
                                                                invoiceSeriesGenerator(scope.row) ===
                                                                $t('sells.orders.undefinedSeriesandNumber')
                                                            "
                                                            :icon="
                                                                scope.row.display_proforma
                                                                    ? 'el-icon-tickets'
                                                                    : 'el-icon-document'
                                                            "
                                                        ></el-button
                                                    ></b>
                                                </el-tooltip>
                                            </div>
                                        </div>
                                    </template>
                                </el-table-column>
                                <el-table-column prop="source" label="Site" width="180"> </el-table-column>
                                <el-table-column :label="$t('general.client')">
                                    <template slot-scope="scope">
                                        <div class="cell-container">
                                            <template v-if="scope.row.customer_pj">
                                                <div>
                                                    {{ scope.row.customer_company | truncate(25, '...') }}
                                                </div>
                                                <div>
                                                    <el-tag class="float-right" type="danger">PJ</el-tag>
                                                </div>
                                            </template>
                                            <template v-else>
                                                <div>
                                                    {{ scope.row.customer_name | truncate(25, '...') }}
                                                </div>
                                                <div>
                                                    <el-tag class="float-right" type="success">PF</el-tag>
                                                </div>
                                            </template>
                                        </div>
                                    </template>
                                </el-table-column>
                                <el-table-column prop="date" :label="$t('sells.orders.label-1')"> </el-table-column>
                                <el-table-column :label="$t('sells.orders.label-2')">
                                    <template slot-scope="scope">
                                        {{ scope.row.mod_plata | truncate(25, '...') }}
                                    </template>
                                </el-table-column>
                                <el-table-column prop="mod_livrare" :label="$t('sells.orders.label-3')">
                                </el-table-column>
                                <!-- <el-table-column label="Status"  width="145">
                                <template slot-scope="scope">
                                    <el-tag size="small" :type="getStatusTag(scope.row)">{{getStatusText(scope.row)}}</el-tag>
                                </template>
                            </el-table-column> -->
                                <el-table-column
                                    prop="valoarecomanda"
                                    :label="$t('sells.orders.label-4')"
                                    width="100px"
                                >
                                </el-table-column>
                                <el-table-column
                                    :label="$t('general.actions')"
                                    :width="(status.value == '2' || status.value == '-1' ? '165' : '100') + 'px'"
                                    fixed="right"
                                >
                                    <template slot-scope="scope">
                                        <router-link
                                            :to="{
                                                path: gotoAddress(
                                                    scope.$index,
                                                    formatedOrders(ordersWithStatus(status.value).list)
                                                ),
                                            }"
                                        >
                                            <el-button size="mini" type="primary" icon="el-icon-info" plain>
                                                {{ $t('general.details') }}</el-button
                                            >
                                        </router-link>
                                        <template v-if="status.value == '2'">
                                            <el-tooltip
                                                class="item"
                                                effect="dark"
                                                :content="$t('sells.orders.button-addExcluded')"
                                                placement="left"
                                            >
                                                <el-button
                                                    size="mini"
                                                    type="warning"
                                                    class="mt-4 ml-1"
                                                    icon="el-icon-document-checked"
                                                    plain
                                                    @click.native="addExcluded(scope.row.orders_id)"
                                                ></el-button>
                                            </el-tooltip>
                                        </template>
                                        <template v-if="status.value == '-1'">
                                            <el-tooltip
                                                class="item"
                                                effect="dark"
                                                :content="$t('sells.orders.button-removeExcluded')"
                                                placement="left"
                                            >
                                                <el-button
                                                    size="mini"
                                                    type="warning"
                                                    class="mt-4 ml-1"
                                                    icon="el-icon-document-delete"
                                                    plain
                                                    @click.native="removeExcluded(scope.row.orders_id)"
                                                ></el-button>
                                            </el-tooltip>
                                        </template>
                                    </template>
                                </el-table-column>
                            </el-table>
                            <div class="center">
                                <!--<div class="center" v-if="ordersWithStatus(status.value).moreavailable">-->
                                <div class="separator"></div>
                                <simple-pagination
                                    :currentpage="1"
                                    :pages="
                                        Math.ceil(
                                            ordersWithStatus(status.value).count / ordersWithStatus(status.value).limit
                                        )
                                    "
                                    @changePage="changeOrderPage"
                                    vspbutton="vspButton"
                                    vspbuttonselected="vspButton-selected"
                                    vspbuttonfast="vspButtonFast"
                                >
                                </simple-pagination>
                                <!--<el-button class="center" type="primary" icon="el-icon-more" size="medium" plain @click.native="loadMore(status.value)">{{$t('sells.orders.load-more')}}</el-button>-->
                            </div>
                        </div>
                        <div v-else class="center">
                            <div class="separator"></div>
                            {{ $t('sells.orders.nothing') }}
                        </div>
                    </el-tab-pane>
                </el-tabs>
            </div>
            <div v-else>
                <div class="center">
                    <b>{{ $t('sells.orders.nothing-2') }}</b>
                </div>
            </div>
        </el-card>

        <OrderGenerate
            v-if="isDemo"
            :show_modal="showGenerateModal"
            v-on:close_modal="showGenerateModal = false"
            :payment_methods="this.paymentMethods"
        ></OrderGenerate>
    </div>
</template>

<script>
import { addExcludedOrder, getOrders, getPaymentMethods, removeExcludedOrder } from '@/api/vanzari';
import { debugPromise, filter } from '@/utils/index';
import cloneDeep from 'lodash.clonedeep';
import find from 'lodash.find';
import has from 'lodash.has';
import isEmpty from 'lodash.isempty';
import isUndefined from 'lodash.isundefined';
import Vue from 'vue';
import { mapGetters } from 'vuex';

Vue.filter('truncate', filter);
const SimplePagination = () => import('@celdotro/vue-simplepagination');

const OrderGenerate = () => import('@/components/OrderGenerate');

export default {
    props: ['filterQuery'],
    components: {
        SimplePagination,
        OrderGenerate,
    },
    data() {
        return {
            topLoading: true,
            start: 0,
            orders: [],
            otherSignsValues: [
                {
                    value: 'ee',
                    text: '=',
                },
                {
                    value: 'ne',
                    text: '≠',
                },
            ],
            filters: {
                minDate: {
                    type: 'select',
                    value: '',
                },
                maxDate: {
                    type: 'select',
                    value: '',
                },
                customer: {
                    type: 'input',
                    value: '',
                },
                customerSign: {
                    type: 'compare',
                    value: 'ee',
                },
                product: {
                    type: 'input',
                    value: '',
                },
                productSign: {
                    type: 'compare',
                    value: 'ee',
                },
                order: {
                    type: 'input',
                    value: '',
                },
                invoice: {
                    type: 'input',
                    value: '',
                },
                order_status: {
                    type: 'select',
                    value: '0',
                },
                payment_method: {
                    type: 'select',
                    value: '',
                },
                phoneNumber: {
                    type: 'input',
                    value: '',
                },
                proformaNumber: {
                    type: 'input',
                    value: '',
                },
                onlyPickupLocker: {
                    type: 'checkbox',
                    value: 0,
                },
            },
            statusValues: [
                {
                    value: '1',
                    text: this.$t('sells.orders.orders-unallocated'),
                    singleText: this.$t('sells.orders.order-unallocated'),
                    label: 'is-grey',
                    limit: 10,
                    start: 0,
                    currentPage: 1,
                    pages: 1,
                },
                {
                    value: '2',
                    text: this.$t('sells.orders.orders-allocated'),
                    singleText: this.$t('sells.orders.order-allocated'),
                    label: 'is-info',
                    limit: 10,
                    start: 0,
                    currentPage: 1,
                    pages: 1,
                },
                {
                    value: '1000',
                    text: this.$t('sells.orders.orders-cancelrequest'),
                    singleText: this.$t('sells.orders.order-cancelrequest'),
                    label: 'is-primary',
                    limit: 10,
                    start: 0,
                    currentPage: 1,
                    pages: 1,
                },
                {
                    value: '-1',
                    text: this.$t('sells.orders.orders-excluded'),
                    singleText: this.$t('sells.orders.order-excluded'),
                    label: 'is-yellow',
                    limit: 100,
                    start: 0,
                    currentPage: 1,
                    pages: 1,
                },
                /*{
						value: '21',
						text: this.$t('sells.orders.orders-ready'),
						singleText: this.$t('sells.orders.order-ready'),
						label: 'is-yellow',
						limit: 10,
						start: 0,
						currentPage: 1,
						pages: 1
				},*/
                {
                    value: '25',
                    text: this.$t('sells.orders.orders-finished'),
                    singleText: this.$t('sells.orders.order-finished'),
                    label: 'is-success',
                    limit: 10,
                    start: 0,
                    currentPage: 1,
                    pages: 1,
                },
                {
                    value: '11',
                    text: this.$t('sells.orders.orders-cancelled'),
                    singleText: this.$t('sells.orders.order-cancelled'),
                    label: 'is-black',
                    limit: 10,
                    start: 0,
                    currentPage: 1,
                    pages: 1,
                },
                // {
                // 	value: '9',
                // 	text: this.$t('sells.orders.order-proform'),
                // 	singleText: this.$t('sells.orders.order-proform'),
                // 	label: 'is-darkerOrange',
                // 	limit: 10,
                // 	start: 0
                // },
                {
                    value: '-2',
                    text: this.$t('sells.orders.orders-pending-storno'),
                    singleText: this.$t('sells.orders.order-pending-storno'),
                    label: 'is-yellow',
                    limit: 100,
                    start: 0,
                    currentPage: 1,
                    pages: 1,
                },
                {
                    value: '24',
                    text: this.$t('sells.orders.orders-storno'),
                    singleText: this.$t('sells.orders.order-storno'),
                    label: 'is-primary',
                    limit: 10,
                    start: 0,
                    currentPage: 1,
                    pages: 1,
                },
                {
                    value: '26',
                    text: this.$t('sells.orders.orders-disputed'),
                    singleText: this.$t('sells.orders.order-disputed'),
                    label: 'is-primary',
                    limit: 10,
                    start: 0,
                    currentPage: 1,
                    pages: 1,
                },
                // {
                //     value: 23,
                //     text: 'Factura emisa',
                //     singleText: 'Factura emisa',
                //     label: 'is-darkBlue',
                //     limit: 10,
                //     start: 0
                // },
            ],
            size: 'medium',
            selectedStatus: '',
            paymentMethods: [],
            currentStatus: 0,
            showGenerateModal: false,
        };
    },
    methods: {
        invoiceSeriesGenerator(data) {
            let returnData = false;

            if (data.proforma_series.length > 0 && data.proforma_number.length > 0) {
                returnData =
                    this.$t('sells.orders.titleipNumber') + ' ' + data.proforma_series + '' + data.proforma_number;
            }

            if (data.order_series.length > 0 && data.orders_number.length > 0) {
                returnData = this.$t('sells.orders.titleiNumber') + ' ' + data.order_series + '' + data.orders_number;
            }

            return returnData ? returnData : this.$t('sells.orders.undefinedSeriesandNumber');
        },
        handleTabChange(event) {
            this.currentStatus = this.statusValues[event.index].value;
        },
        async changeOrderPage(page) {
            const vm = this;
            const index = vm.statusValues.findIndex((obj) => parseInt(obj.value) === parseInt(this.currentStatus));
            if (index < 0) return false;
            vm.statusValues[index]['start'] = (page - 1) * 10;
            // vm.filters.order_status.value = this.currentStatus
            const localFilters = cloneDeep(this.filters);
            localFilters.order_status.value = this.currentStatus;
            await this.getOrdersForPage(localFilters, this.currentStatus);
            if (typeof this.updateQuery === 'function') this.updateQuery();
        },
        async getOrdersForPage(filters, specificStatus) {
            this.topLoading = false;
            const params = {
                limit: 10,
            };

            const parsedFilters = {};
            if (!isUndefined(filters)) {
                for (const prop in filters) {
                    parsedFilters[prop] = filters[prop].value;
                }
            }
            params.filters = JSON.stringify(parsedFilters);

            if (specificStatus) {
                const index = this.statusValues.findIndex((obj) => parseInt(obj.value) === parseInt(specificStatus));
                params.start = this.statusValues[index]['start'];
            } else {
                params.start = this.start;
            }

            return getOrders(params)
                .then((res) => {
                    if ('undefined' == typeof res) {
                        this.$router
                            .push({
                                name: 'Profil public',
                            })
                            .catch(() => {});
                        return;
                    }
                    if (res == null || typeof res.message === 'undefined') return false;

                    if (specificStatus) {
                        if (!isEmpty(res.message)) {
                            if (res.message[parseInt(specificStatus)] == null) return false;
                            this.orders[parseInt(specificStatus)].list = res.message[parseInt(specificStatus)].list;
                            this.orders[parseInt(specificStatus)].moreavailable =
                                res.message[parseInt(specificStatus)].moreavailable;
                            this.orders[parseInt(specificStatus)].pages = Math.ceil(
                                res.message[parseInt(specificStatus)].count /
                                    res.message[parseInt(specificStatus)].limit
                            );
                        }
                    } else {
                        this.orders = res.message;
                    }
                    getPaymentMethods().then((res) => {
                        this.paymentMethods = res.message;
                    });
                })
                .catch((e) => {
                    this.$reportToSentry(e, {
                        extra: {
                            fn: 'getOrders',
                            params,
                        },
                    });
                    debugPromise('getOrders', e);
                });
        },
        getStatusTag(row) {
            const status = find(this.statusValues, (item) => row.status === item.value);
            return has(status, 'label') ? status.label : 'primary';
        },
        getStatusText(row) {
            const status = find(this.statusValues, (item) => row.status === item.value);
            return has(status, 'singleText') ? status.singleText : 'Necunoscut';
        },
        resetOrdersLimitAndStart() {
            this.statusValues = [
                {
                    value: '1',
                    text: this.$t('sells.orders.orders-unallocated'),
                    singleText: this.$t('sells.orders.order-unallocated'),
                    label: 'is-info',
                    limit: 10,
                    start: 0,
                    pages: 1,
                },
                {
                    value: '2',
                    text: this.$t('sells.orders.orders-allocated'),
                    singleText: this.$t('sells.orders.order -allocated'),
                    label: 'is-info',
                    limit: 10,
                    start: 0,
                    pages: 1,
                },
                {
                    value: '1000',
                    text: this.$t('sells.orders.orders-cancelrequest'),
                    singleText: this.$t('sells.orders.order-cancelrequest'),
                    label: 'is-primary',
                    limit: 10,
                    start: 0,
                    pages: 1,
                },
                {
                    value: '-1',
                    text: this.$t('sells.orders.orders-excluded'),
                    singleText: this.$t('sells.orders.order-excluded'),
                    label: 'is-yellow',
                    limit: 100,
                    start: 0,
                    currentPage: 1,
                    pages: 1,
                },
                /*{
						value: '21',
						text: this.$t('sells.orders.orders-ready'),
						singleText: this.$t('sells.orders.order-ready'),
						label: 'is-yellow',
						limit: 10,
						start: 0,
						currentPage: 1,
						pages: 1
				},*/
                {
                    value: '25',
                    text: this.$t('sells.orders.orders-finished'),
                    singleText: this.$t('sells.orders.order-finished'),
                    label: 'is-darkGreen',
                    limit: 10,
                    start: 0,
                    pages: 1,
                },
                {
                    value: '11',
                    text: this.$t('sells.orders.orders-cancelled'),
                    singleText: this.$t('sells.orders.order-cancelled'),
                    label: 'is-black',
                    limit: 10,
                    start: 0,
                    pages: 1,
                },
                // {
                // 	value: '9',
                // 	text: this.$t('sells.orders.order-proform'),
                // 	singleText: this.$t('sells.orders.order-proform'),
                // 	label: 'is-darkerOrange',
                // 	limit: 10,
                // 	start: 0
                // },
                {
                    value: '-2',
                    text: this.$t('sells.orders.orders-pending-storno'),
                    singleText: this.$t('sells.orders.order-pending-storno'),
                    label: 'is-yellow',
                    limit: 100,
                    start: 0,
                    currentPage: 1,
                    pages: 1,
                },
                {
                    value: '24',
                    text: this.$t('sells.orders.orders-storno'),
                    singleText: this.$t('sells.orders.order-storno'),
                    label: 'is-primary',
                    limit: 10,
                    start: 0,
                    pages: 1,
                },
                {
                    value: '26',
                    text: this.$t('sells.orders.orders-disputed'),
                    singleText: this.$t('sells.orders.order-disputed'),
                    label: 'is-primary',
                    limit: 10,
                    start: 0,
                    currentPage: 1,
                    pages: 1,
                },
                // {
                //     value: 23,
                //     text: 'Factura emisa',
                //     singleText: 'Factura emisa',
                //     label: 'is-darkBlue',
                //     limit: 10,
                //     start: 0
                // },
            ];
        },
        async loadMore(status) {
            const vm = this;
            const index = vm.statusValues.findIndex((obj) => parseInt(obj.value) === parseInt(status));
            vm.statusValues[index]['start'] += 10;
            // vm.filters.order_status.value = status
            await this.getOrders(this.filters, status);
            if (typeof this.updateQuery === 'function') this.updateQuery();
        },
        async getOrders(filters, specificStatus) {
            this.topLoading = false;
            const params = {
                limit: 10,
            };

            const parsedFilters = {};
            if (!isUndefined(filters)) {
                for (const prop in filters) {
                    parsedFilters[prop] = filters[prop].value;
                }
            }
            params.filters = JSON.stringify(parsedFilters);

            if (specificStatus) {
                const index = this.statusValues.findIndex((obj) => parseInt(obj.value) === parseInt(specificStatus));
                params.start = this.statusValues[index]['start'];
            } else {
                params.start = this.start;
            }

            try {
                const res = await getOrders(params);
                if ('undefined' == typeof res) {
                    this.$router
                        .push({
                            name: 'Profil public',
                        })
                        .catch(() => {});
                    return;
                }
                if (res == null || typeof res.message === 'undefined') return false;

                if (specificStatus) {
                    if (!isEmpty(res.message)) {
                        this.orders[parseInt(specificStatus)].list = this.orders[parseInt(specificStatus)].list.concat(
                            res.message[parseInt(specificStatus)].list
                        );
                        this.orders[parseInt(specificStatus)].moreavailable =
                            res.message[parseInt(specificStatus)].moreavailable;
                    }
                } else {
                    this.orders = res.message;
                }
                const paymentMethods = await getPaymentMethods();
                this.paymentMethods = paymentMethods.message;
            } catch (e) {
                if (
                    'undefined' !== typeof e.message &&
                    e.message.indexOf(
                        'Cont blocat pana la modificarea in cont a datelor care incalca prevederile contractuale'
                    ) !== -1
                ) {
                    this.$router
                        .push({
                            name: 'Profil public',
                        })
                        .catch(() => {});
                    return;
                }

                debugPromise('getOrders', e);
            }
        },
        async addExcluded(id) {
            return addExcludedOrder({ id }).then((res) => {
                this.resetFilters();
                setTimeout(this.highlightEffect.bind(null, 3), 220);
            });
        },
        async removeExcluded(id) {
            return removeExcludedOrder({ id }).then((res) => {
                this.resetFilters();
                setTimeout(this.highlightEffect.bind(null, 3), 220);
            });
        },
        highlightEffect(tabId) {
            const el = document.querySelector(`.el-tabs__nav #tab-${tabId}`);
            if (el == null) return false;

            el.classList.remove('highlight');
            el.classList.add('highlight');

            setTimeout(() => {
                el.classList.remove('highlight');
            }, 500);
        },
        async applyFilters() {
            this.resetOrdersLimitAndStart();
            await this.getOrders(this.filters);
            this.selectTab();
            if (typeof this.updateQuery === 'function') this.updateQuery();
        },
        selectTab() {
            for (const i in this.statusValues) {
                if (
                    this.statusValues[i].value == this.filters.order_status.value ||
                    (this.filters.order_status.value == 0 && i == 0)
                ) {
                    this.selectedStatus = i;
                    break;
                }
            }
        },
        async resetFilters() {
            this.filters = {
                minDate: {
                    type: 'select',
                    value: '',
                },
                maxDate: {
                    type: 'select',
                    value: '',
                },
                customer: {
                    type: 'input',
                    value: '',
                },
                customerSign: {
                    type: 'compare',
                    value: 'ee',
                },
                product: {
                    type: 'input',
                    value: '',
                },
                productSign: {
                    type: 'compare',
                    value: 'ee',
                },
                order: {
                    type: 'input',
                    value: '',
                },
                invoice: {
                    type: 'input',
                    value: '',
                },
                order_status: {
                    type: 'select',
                    value: '0',
                },
                payment_method: {
                    type: 'select',
                    value: '',
                },
                phoneNumber: {
                    type: 'input',
                    value: '',
                },
                proformaNumber: {
                    type: 'input',
                    value: '',
                },
                onlyPickupLocker: {
                    type: 'checkbox',
                    value: 0,
                },
            };
            this.resetOrdersLimitAndStart();
            await this.getOrders();
            if (typeof this.updateQuery === 'function') this.updateQuery();
        },
        gotoAddress(index, order) {
            return '/vanzari/comanda/' + order[index].orders_id;
        },
        ordersWithStatus(status) {
            if (parseInt(status) in this.orders) {
                // this.orders[parseInt(status)].list = formatedOrders
                return this.orders[parseInt(status)];
                // return formatedOrders
            }
            return false;
        },
        formatedOrders(orders) {
            const formatedOrders = orders.map((item) => {
                return {
                    customer_name: item.customers_name,
                    customer_company:
                        item.customers_company.trim().length > 0 ? item.customers_company : item.billing_company,
                    date: item.date_purchased,
                    mod_livrare: item.delivery_company,
                    mod_plata: item.payment_method,
                    orders_id: item.orders_id,
                    source: item.customers_state.toUpperCase(),
                    status: item.status,
                    valoarecomanda: parseInt(item.order_total),
                    orders_number: item.nr_fact,
                    order_series: item.serie,
                    proforma_series: item.proforma_serie,
                    proforma_number: item.proforma_numar,
                    display_proforma:
                        item.proforma_serie.length > 0 &&
                        item.proforma_numar.length > 0 &&
                        (item.serie.length < 1 || item.nr_fact.length < 1),
                    customer_pj:
                        (item.customers_company.trim().length > 0 || item.billing_company.trim().length > 0) &&
                        item.customers_cui.length > 0,
                };
            });
            return formatedOrders;
        },
        formatLabelText({ value, text }) {
            return this.ordersWithStatus(value)
                ? text + ' [' + this.ordersWithStatus(value).count + ']'
                : text + ' [0]';
        },
        getSize() {
            const width = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;

            if (width < 1366) return 'small';

            return 'small';
        },
    },
    computed: {
        ...mapGetters({
            isDemo: 'user/isDemo',
        }),
        checkIfOrders() {
            return !isEmpty(this.orders) || false;
        },
    },
    async mounted() {
        this.size = this.getSize();
        await this.getOrders(this.filters);
    },
};
</script>

<style>
.vspButton {
    height: 22px;
    padding: 2px 7px;
    font-size: 12px;
    display: inline-block;
    margin-bottom: 0;
    font-weight: 400;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    -ms-touch-action: manipulation;
    touch-action: manipulation;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    background-image: none;
    border: 1px solid transparent;
    border-radius: 4px;
    background-color: transparent;
}

button[class='vspButton']:hover {
    background-color: #ffeee7;
    border: 1px solid #ff5f2d;
}

.vspButton-selected {
    background-color: #ff5f2b;
    color: white;
}

.vspButtonFast {
    background-color: #f4f8fb;
    color: #b5b9bc;
}

.svg-icon.is-info {
    width: initial;
    height: initial;
    color: #3273dc;
}

.svg-icon.is-black {
    width: initial;
    height: initial;
    color: black;
}

.svg-icon.is-success {
    width: initial;
    height: initial;
    color: #23d160;
}

.svg-icon.is-primary {
    width: initial;
    height: initial;
    color: #ed6331;
}

.svg-icon.is-danger {
    width: initial;
    height: initial;
    color: #ed6331;
}

.svg-icon.is-yellow {
    width: initial;
    height: initial;
    color: gold;
}

.tag.is-yellow {
    background-color: gold;
}

.svg-icon.is-grey {
    width: initial;
    height: initial;
    color: grey;
}

.svg-icon.is-darkerOrange {
    width: initial;
    height: initial;
    color: #d06f00;
}

.tag.is-darkerOrange {
    background-color: #d06f00;
}

.svg-icon.is-darkGreen {
    width: initial;
    height: initial;
    color: #228b22;
}

.tag.is-darkGreen {
    background-color: #228b22;
}

.svg-icon.is-darkBlue {
    width: initial;
    height: initial;
    color: #0287b6;
}

.tag.is-darkBlue {
    background-color: #0287b6;
}

.cell-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.mt-4 {
    margin-top: 4px;
}

@keyframes highlight {
    0% {
        background-color: transparent;
    }
    50% {
        background-color: hsl(19deg 100% 57% / 27%);
    }
    100% {
        background-color: transparent;
    }
}

.highlight {
    animation-name: highlight;
    animation-duration: 0.5s;
}
</style>
